import React from 'react';
import showAlert from '../../../common/toaster';
import { checkHasMasteredTheSkill } from '../../../../utils/reader';
import { closeModal, openModal } from '../../../../actions/eventEmitter';
import { Modal } from 'sharedfrontend';
import { postDataPlain } from '../../../../actions/api';
import { updateHash } from '../../../../utils/iframe';
import { masteryPercentCeilings } from '../../../../utils/data';
import { ImplementationType, ProblemType } from '../../../../utils/constants';
import HintsModal from '../../modals/hints/container';
import { handleExitButtonClick } from '../../../../utils/common';

class LearnosityQuestion extends React.Component<any, any> {
  questionsApp: any = null;
  startTimestamp: any;

  componentDidMount() {
    this.questionsApp = (window as any).LearnosityApp.init(this.props.learnosityJson, {
      errorListener: (e: Error) => {
        console.log('error', e);
      },
      readyListener: () => {
        this.startTimestamp = +new Date();

        if (this.props.problemRendered) {
          this.props.problemRendered();
        }
      },
    });
  }

  componentDidUpdate() {
    if (this.props.submittedResponse) {
      this.submitResponse();
      this.startTimestamp = +new Date();
    }
  }

  submitResponse = async () => {
    try {
      const response: any = this.questionsApp.getResponses();
      const timeSpentInSeconds = this.startTimestamp ? Math.floor((+new Date() - this.startTimestamp) / 1000) : 0;

      this.props.gradeResponse({
        id: this.props.id,
        type: this.props.type,
        response,
        timeSpentInSeconds,
        callback: (params: any) => gradeResponseCallback(params, this.props.showStepAnswer),
      });
    }
    catch (e) {
      showAlert({ message: 'Oops, we were not able to save your response! Please try again.', isError: true });
    }
  }

  render() {
    const { id } = this.props;

    return (
      <div id={`container-${id}`}>
        <span className={`learnosity-response question-${id}`}></span>
      </div>
    );
  }
};

export default LearnosityQuestion;

const gradeResponseCallback = (params: any, showStepAnswer: Function) => {
  if (params.isBenchmark) {
    const { nextProblemId, isValidAttempt, problemSetName, classId, isInvalidLevelSetAttempt } = params;
    const answeredLastProblem = !nextProblemId && isValidAttempt;

    if (isInvalidLevelSetAttempt) {
      openModal(
        <Modal
          title="Oops!"
          maxWidth="sm"
          okText="Exit"
          okCallback={() => {
            handleExitButtonClick({ classId });
            closeModal();
          }}
          cancelText=""
        >
          <p>
            A problem has occurred, notify your teacher to check for a message in the LevelSet report.
          </p>
        </Modal>
      );
    }
    else if (answeredLastProblem) {
      openModal(
        <Modal
          title="Awesome!"
          maxWidth="sm"
          okText="Exit"
          okCallback={() => {
            handleExitButtonClick({ classId });
            closeModal();
          }}
          cancelText=""
        >
          <p>
            You have completed {problemSetName}!
          </p>
        </Modal>
      );
    }
  }
  else if (params.wasInterventionCreated) {
    const { problemSetName, stepProblemSetName, stepProblemSetId, classId } = params;

    openModal(
      <Modal
        title="Wait a sec..."
        maxWidth="sm"
        okText="Yes, take me there!"
        okCallback={() => {
          closeModal();
          updateHash({ path: `/mathreader?problemSetId=${stepProblemSetId}&classId=${classId}`, redirect: true });
        }}
        cancelText="Maybe later"
        cancelCallback={closeModal}
      >
        <p>
          It looks like you may need some help. Practice problems on <b>{stepProblemSetName}</b>&nbsp;
          to help you reach mastery in <b>{problemSetName}</b>.
        </p>
      </Modal>
    );
  }
  else if (params.type === ImplementationType.STEP &&
      !params.isCorrect &&
      params.stepProblemType !== ProblemType.MCQ
  ) {
    const { numAttemptsInStepProblem, numStepHints, numViewedStepHints } = params;
    // After second incorrect response to a step show hints if not all viewed
    // or show answer if all hints viewed
    if (numStepHints === numViewedStepHints) {
      if (numAttemptsInStepProblem > 2) {
        const { id: stepId, problemSetId, classId } = params;
        showStepAnswer(stepId, problemSetId, classId);
      }
    }
    else if (numAttemptsInStepProblem > 1) {
      openModal(<HintsModal numViewedStepHints={numViewedStepHints} />);
    }
  }
  else if (checkHasMasteredTheSkill(params)) {
    const {
      problemSetName,
      classId,
      problemSetId,
      createdFromProblemSetId,
      createdFromProblemSetName,
      assignmentSource,
    } = params;

    if (!!createdFromProblemSetId) {
      openModal(
        <Modal
          title=""
          maxWidth="sm"
          okText="Exit review"
          okCallback={() => {
            closeModal();
            updateHash({
              path: `/mathreader?problemSetId=${createdFromProblemSetId}&classId=${classId}`,
              redirect: true,
            });
          }}
          cancelText="Keep practicing"
          cancelCallback={closeModal}
        >
          <p>
            Congratulations! You have mastered the skill:<br/>
            {problemSetName}
          </p>
          <p>
            Now you can pick up where you left off in:<br/>
            {createdFromProblemSetName}
          </p>
        </Modal>
      );
    }
    else {
      // Triggering from frontend, so that we don't have to fetch mastery and numProblemsCompleted
      // everytime S solves a problem
      if (assignmentSource === 'postlevelset' || assignmentSource === 'postnwea') {
        postDataPlain({
          type: 'TRIGGER_ASSIGNMENT_AUTO_ASSIGN',
          params: {
            problemSetId,
            classId,
            benchmarkSource: assignmentSource === 'postlevelset' ? 'levelset' : 'nwea',
          }
        });
      }

      openModal(
        <Modal
          title=""
          maxWidth="sm"
          okText="Exit assignment"
          okCallback={() => {
            handleExitButtonClick({ classId });
            closeModal();
          }}
          cancelText="Keep practicing"
          cancelCallback={closeModal}
        >
          <p>
            Congratulations! You have mastered the skill:<br/>
            {problemSetName}
          </p>
        </Modal>
      );
    }
  }
  else if (params.isCorrect && !params.nextProblemId && params.isValidAttempt && params.isSameProblemSetId) {
    const { classId, problemSetId, newMastery, assignmentSource } = params;
    if (newMastery < masteryPercentCeilings.approaching) {
      postDataPlain({ type: 'TRIGGER_ASSIGNMENT_AUTO_RESET', params: { problemSetId } });

      openModal(
        <Modal
          title=""
          maxWidth="sm"
          okText="Exit assignment"
          okCallback={() => {
            handleExitButtonClick({ classId });
            closeModal();
          }}
        >
          <p>
            You have not mastered this skill, but have attempted all the problems.
            Please try again and make use of the videos, steps, and hints to help you succeed.
          </p>
        </Modal>
      );
    }
    else if (assignmentSource === 'postlevelset' || assignmentSource === 'postnwea') {
      postDataPlain({
        type: 'TRIGGER_ASSIGNMENT_AUTO_ASSIGN',
        params: {
          problemSetId,
          classId,
          benchmarkSource: assignmentSource === 'postlevelset' ? 'levelset' : 'nwea',
        }
      });
    }
  }
};